 <div class="container-fluid" *ngIf="productInfo$ | async as productInfo">
  <div class="row">
    <div class="col">
      <mat-slide-toggle color="primary" #expert>{{
        expert.checked ? "Экспертный режим" : "Режим менеджера"
      }}</mat-slide-toggle>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col-auto">
      <h1 class="h1">
        {{ productInfo.nomen }}
      </h1>
    </div>
    <div class="col-auto">
      <button
        *ngIf="expert.checked"
        mat-icon-button
        class="btn-primary"
        matTooltip="Предложить исправления"
        matTooltipClass="tooltip"
        (click)="setRecNomen()"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col">
          <app-img-gallery [img]="productInfo.photoList"></app-img-gallery>
          <div *ngIf='prodInfo.gift' style='position: absolute;right: 0;top:0'>
            <button mat-icon-button class='btn btn-danger' matTooltipClass='tooltip' [matTooltip]='prodInfo.brandGift'><mat-icon>card_giftcard</mat-icon></button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-divider></mat-divider>
        </div>
      </div>
      <div class="row my-2">
        <div class="col">
          <div class="row my-1">
            <div class="col-4">{{ "PRODUCT.KATAL_NUMBER" | translate }}:</div>
            <div class="col">
              <b>{{ productInfo.katalNumber }}</b>
            </div>
          </div>

          <div class="row my-1">
            <div class="col-4">{{ "PRODUCT.BRAND" | translate }}:</div>
            <div class="col">
              <b>{{ productInfo.brand }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-divider></mat-divider>
        </div>
      </div>
      <div class="row my-2">
        <div class="col">
          <div class="row">
            <div class="col-auto">
              <h3>{{ "PRODUCT.PRESENTATION" | translate }}</h3>
            </div>

            <div class="col-auto">
              <button
                *ngIf="expert.checked"
                mat-icon-button
                class="btn-primary"
                matTooltip="Предложить исправления"
                matTooltipClass="tooltip"
                (click)="setBrandRec()"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-4">{{ "PRODUCT.COUNTRY" | translate }}</div>
            <div class="col">
              <b>{{ productInfo.country }}</b>
            </div>
          </div>

          <div class="row my-2">
            <div class="col-4">{{ "PRODUCT.QUALITY" | translate }}</div>
            <div class="col">
              <b>{{ productInfo.quality }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-divider></mat-divider>
        </div>
      </div>
      <div class="row my-2">
        <div class="col">
          <div class="h3">{{ "PRODUCT.SPECIALIZATION" | translate }}</div>
          <div class="row">
            <div class="col">
              <b>{{ productInfo.specification }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-divider></mat-divider>
        </div>
      </div>
      <div class="row my-2">
        <div class="col">
          <div class="h3">
            {{ "PRODUCT.FOR_BRAND" | translate }} [{{
              productInfo.pronunciation
            }}]
          </div>
          <div class="row">
            <div class="col">
              {{ productInfo.infoBrand }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div>
        <mat-tab-group mat-align-tabs="start">
          <mat-tab label="Основное">
            <table class="table table-striped table-bordered table-sm">
              <tr *ngIf="productInfo.specInfo">
                <th colspan="3" class="text-left px-2">&nbsp;</th>
              </tr>
              <tr *ngIf="productInfo.specInfo">
                <th colspan="3" class="text-left bg-warning px-2">
                  {{ "PRODUCT.SPEC_INFO" | translate }}
                </th>
              </tr>
              <tr *ngIf="productInfo.specInfo">
                <td colspan="3" class="text-left bg-warning px-2">
                  {{ productInfo.specInfo }}
                </td>
              </tr>

              <tr>
                <th colspan="3" class="text-left px-2">&nbsp;</th>
              </tr>

              <tr>
                <th class="text-left px-2" colspan="2">
                  {{ "PRODUCT.SETTINGS" | translate }}
                </th>
                <th>
                  <button
                    *ngIf="expert.checked"
                    mat-icon-button
                    class="btn-primary"
                    matTooltip="Добавить характеристику"
                    matTooltipClass="tooltip"
                    (click)="setRecCri()"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </th>
              </tr>
              <tr *ngFor="let item of productInfo.criList">
                <td class="px-2">{{ item.name }}</td>
                <td class="px-2">
                  {{ item.value }}
                </td>
                <td>
                  <mat-icon
                    *ngIf="expert.checked"
                    (click)="deleteCri(item.ID_cri, item.name)"
                    class="cursor-pointer"
                    >delete
                  </mat-icon>
                </td>
              </tr>

              <tr>
                <th colspan="3" class="text-left px-2">&nbsp;</th>
              </tr>
              <tr>
                <th colspan="3" class="text-left px-2">
                  {{ "PRODUCT.STAT" | translate }}
                </th>
              </tr>
              <tr>
                <td colspan="2" class="px-2">
                  {{ "PRODUCT.SALE" | translate }}
                </td>
                <td class="px-2">{{ productInfo.countSaleProd }}</td>
              </tr>
              <tr>
                <td colspan="2" class="px-2">
                  {{ "PRODUCT.RETURN" | translate }}
                </td>
                <td class="px-2">{{ productInfo.countReturn }}</td>
              </tr>
              <tr>
                <th colspan="3" class="text-left px-2">&nbsp;</th>
              </tr>

              <tr>
                <th colspan="3" class="text-left px-2">
                  Описание
                </th>
              </tr>
              <tr>
                <td colspan="3" class="px-2" [innerText]="productInfo.infoRu "></td>
              </tr>


              <tr>
                <th colspan="3" class="text-left px-2">&nbsp;</th>
              </tr>
              <tr>
                <th colspan="2" class="text-left px-2">
                  {{ "PRODUCT.OE" | translate }}
                </th>
                <th>
                  <button
                    *ngIf="expert.checked"
                    mat-icon-button
                    class="btn-primary"
                    matTooltip="Предложить исправления"
                    matTooltipClass="tooltip"
                    (click)="setRecCross()"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </th>
              </tr>
              <tr>
                <td colspan="3" class="px-2">
                  <span
                    *ngFor="let val of productInfo.oemList"
                    class="hover m-1 px-2"
                    [ngClass]="{'text-warning':+val.oe===3}"
                  >
                    {{ val.katalNumber }} {{ val.brand }}
                  </span>
                </td>
              </tr>

              <tr *ngIf="prodInfo.symptomsOfMalfunctions">
                <th colspan="3" class="text-left px-2 bg-warning">
                  Признаки неисправностей
                </th>
              </tr>
              <tr *ngIf="prodInfo.symptomsOfMalfunctions">
                <th colspan="3" class="text-left px-2">
                  {{ prodInfo.symptomsOfMalfunctions }}
                </th>
              </tr>
              <tr *ngIf="prodInfo.replacementRecommendations">
                <th colspan="3" class="text-left px-2 bg-warning">
                  Рекомендации по замене
                </th>
              </tr>
              <tr *ngIf="prodInfo.replacementRecommendations">
                <th colspan="3" class="text-left px-2">
                  {{ prodInfo.replacementRecommendations }}
                </th>
              </tr>
              <tr *ngIf="prodInfo.hubSale">
                <th colspan="3" class="text-left px-2 bg-warning">
                  Детали на которые обратить внимание при замене
                </th>
              </tr>
              <tr *ngIf="prodInfo.hubSale">
                <th colspan="3" class="text-left px-2">
                  <span *ngFor="let item of prodInfo.hubSale">{{item.data}}, </span>
                </th>
              </tr>
              <tr *ngIf="prodInfo.installationFeature">
                <th colspan="3" class="text-left px-2 bg-warning">
                  Особенность установки
                </th>
              </tr>
              <tr *ngIf="prodInfo.installationFeature">
                <th colspan="3" class="text-left px-2 bg-warning">
                  Парная замена
                </th>
              </tr>
            </table>
          </mat-tab>
          <mat-tab label="Применяемость">
            <div *ngFor="let item of productInfo.markModelList">
              <div class="row align-items-center my-3">
                <div class="col-4 col-md-auto">
                  <img [src]="item.img" style="max-width: 50px" alt="mark" />
                </div>
                <div class="col-4 col-md-2">
                  {{ item.marka }}
                </div>
              </div>
              <div class="row">
                <div class="col-12 my-2">
                  <button
                    mat-button
                    class="btn btn-gray mb-2 mr-2"
                    *ngFor="let val of item.model"
                    (click)="onOpenApplicabilityCar(item.marka, val)"
                  >
                    {{ val }}
                  </button>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <a
                mat-button
                class="btn btn-primary"
                [href]="
                  '/crm/search-global/' +
                  productInfo.katalNumber +
                  '/' +
                  productInfo.brand
                "
                >Аналоги</a
              >
            </ng-template>
          </mat-tab>
          <mat-tab *ngIf="prodCard?.length > 0">
            <ng-template mat-tab-label>
              <a
                mat-button
                class="btn btn-primary"
                [href]="
                  '/crm/product-edit-card/' +
                  productInfo.katalNumber +
                  '/' +
                  productInfo.brand
                "
                target="_blank"
                >Карточка товара</a
              >
            </ng-template>
          </mat-tab>
        </mat-tab-group>
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Спец. информация о товаре</mat-label>
          <textarea rows="4" matInput [formControl]="specInfo"></textarea>
        </mat-form-field>
      </div>
      <div class="text-right">
        <button
          type="button"
          mat-button
          class="btn btn-success"
          (click)="onSaveSpecInfo()"
        >
          Соханить
        </button>
      </div>
    </div>
  </div>
  <div class="row my-2 mb-5">
    <div class="col">
      <div>
        <div
          class="row align-items-center py-2 hover align-items-center"
          style="border-bottom: 1px solid #000"
          *ngFor="let item of productInfo.warehouseList"
          [ngClass]="{ rowBackground: +item.countProvider === 210 }"
        >
          <div class="col-2 text-center">
            <div>
              {{ "PRODUCT.STORE" | translate }} - <b>{{ item.codeProvider }}</b>
            </div>
            <div class="my-1 copy-content" style="position: relative">
              {{ item.zakazNumber }}
              <button
                [cdkCopyToClipboard]="item.zakazNumber"
                (click)="trueCopy()"
                class="copy-content__btn mat-icon-button"
                mat-icon-button
                matTooltip="Скопировать код заказа"
                matTooltipClass="tooltip"
                matTooltipPosition="below"
              >
                <span class="mat-button-wrapper"
                  ><mat-icon
                    class="material-icons_grey copy-content__icon mat-icon notranslate material-icons mat-icon-no-color"
                    role="img"
                    aria-hidden="true"
                    >content_copy</mat-icon
                  ></span
                >
              </button>
            </div>
            <div
              matTooltipClass="tooltip"
              [matTooltip]="
                'Изменение сроков ' +
                item.changeDate +
                '% | Изменение кол-ва ' +
                item.changeEdit +
                '%'
              "
            >
              <span
                class="btn btn-small"
                [ngClass]="{
                  'btn-danger': +item.ratingProvider < 90,
                  'btn-warning':
                    +item.ratingProvider >= 90 && +item.ratingProvider < 95,
                  indicatorComment: +item.ratingProvider >= 95
                }"
                >{{ item.ratingProvider }} %</span
              >
            </div>
          </div>
          <div class="col-3 px-1">
            <div class="row">
              <div class="col text-danger">{{ item.saleUnit }} юнитов.</div>
            </div>
            <app-provider-count-table
              [providerInfo]="item.countProvider"
            ></app-provider-count-table>
            {{ item.nomenPrice }}
          </div>
          <div class="col-2 px-1 text-center">
            <div [ngSwitch]="item.infoLabel">
              <div
                *ngSwitchCase="1"
                class="saleRate px-3 py-2"
                [matTooltip]="'SEARCH_GLOBAL.SALE_TIME' | translate"
                matTooltipClass="tooltip"
              >
                {{ "SEARCH_GLOBAL.SALE" | translate }}
              </div>
              <div *ngSwitchCase="2" class="countSale px-3 py-2">
                {{ "SEARCH_GLOBAL.TOP" | translate }}
              </div>
              <div *ngSwitchCase="3" class="selfRec px-3 py-2">
                {{ "SEARCH_GLOBAL.REC" | translate }}
              </div>
            </div>

            <div class="my-1" [ngSwitch]="+item.saleCost.type">
              <div *ngSwitchCase="1">
                <div class="d-block align-items-center">
                  <span class="h4 pr-2"
                    ><b>{{ "SEARCH_GLOBAL.COST" | translate }}:</b></span
                  >
                  <span
                    style="position: relative"
                    class="text-center text-dark"
                  >
                    <b>{{ item.saleCost.oldCost }}</b> грн.
                    <div
                      style="
                        border-bottom: 1px solid #eb5757;
                        position: absolute;
                        width: 100%;
                        top: 0.6rem;
                        left: 0;
                        transform: rotate(13deg);
                      "
                    ></div>
                  </span>
                  <div class="text-center">
                    <span class="h2 text-danger"
                      ><b>{{ item.saleCost.newCost }}</b></span
                    >
                    &nbsp;<span class="text-danger">грн.</span>
                  </div>

                  <div class="text-warning">
                    Минимум
                    <b>{{ item.saleCost.minCost }} грн.</b>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="2">
                <div class="d-block align-items-center">
                  <span class="h4 pr-2"
                    ><b>{{ "SEARCH_GLOBAL.COST" | translate }}:</b></span
                  >
                  <span
                    style="position: relative"
                    class="text-center text-dark"
                  >
                    <b>{{ item.saleCost.oldCost }}</b> грн.
                    <div
                      style="
                        border-bottom: 1px solid #eb5757;
                        position: absolute;
                        width: 100%;
                        top: 0.6rem;
                        left: 0;
                        transform: rotate(13deg);
                      "
                    ></div>
                  </span>
                  <div class="text-center">
                    <span class="h2 text-danger"
                      ><b>{{ item.saleCost.newCost }}</b></span
                    >
                    &nbsp;<span class="text-danger">грн.</span>
                  </div>

                  <div class="text-warning">
                    Минимум
                    <b>{{ item.saleCost.minCost }} грн.</b>
                  </div>
                </div>
              </div>
              <div *ngSwitchDefault>
                <button
                  [matMenuTriggerFor]="menu"
                  mat-button
                  class="btn btn-outline-primary"
                >
                  <b
                    >{{ "SEARCH_GLOBAL.COST" | translate }}
                    {{ item.saleCost.oldCost }} грн.</b
                  >
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item>
                    {{ "SEARCH_GLOBAL.COST_SALE" | translate }}
                    <b>{{ item.saleCost.newCost }} грн.</b>
                  </button>

                  <button mat-menu-item class="text-warning">
                    Минимум
                    <b>{{ item.saleCost.minCost }} грн.</b>
                  </button>

                </mat-menu>
              </div>
            </div>

            <div class="my-1" *ngIf="item.specInfo">
              <button
                mat-button
                class="btn btn-small btn-warning"
                [matTooltip]="item.specInfo"
                matTooltipClass="tooltip"
                matTooltipPosition="after"
              >
                <mat-icon> info</mat-icon>
              </button>
            </div>

            <div *ngIf="item.infoManger" [innerHTML]="item.infoManger"></div>
            <div
              *ngIf="item.countWarehouse"
              [innerHTML]="item.countWarehouse"
            ></div>
            <div *ngIf="item.countWarehouse">
              <button
                mat-button
                class="btn btn-primary"
                (click)="showWarehouseInfo(item.warehouseText)"
              >
                Работа складов
              </button>
            </div>
          </div>
          <div class="col-5">
            <app-form-add-to-order
              [specInfo]="productInfo.specInfo"
              [orderList]="item.orderList"
              [setOe]="1"
              [oeNum]="oeNum"
              [katalNumber]="productInfo.katalNumber"
              [brand]="productInfo.brand"
              [id]="item.id"
            ></app-form-add-to-order>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { PackingOrder, ProductList, TtnInfo } from '../core/pi';
import { PsService } from '../core/ps.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { catchError, filter, tap } from 'rxjs/operators';
import { PackingDialogSaveSelfTtnComponent } from '../packing-dialog-save-self-ttn/packing-dialog-save-self-ttn.component';
import { Router } from '@angular/router';
import {IProductBarcodeInfo, SrvService} from 'repositories';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-pacing-dialog-create-ttn',
  templateUrl: './packing-dialog-create-ttn.component.html',
  styleUrls: ['./packing-dialog-create-ttn.component.scss'],
  providers: [PsService],
})
export class PackingDialogCreateTtnComponent implements OnInit {
  form: FormGroup;
  ttnInfo$: Observable<TtnInfo>;
  createTtnInfo: TtnInfo;
  settlementSet = [];
  response = {
    code: 0,
    ttn: '',
    link: '',
  };
  disabled = false;
  checkComment: boolean;
  costOrder = 0;

  constructor(
    private ps: PsService,
    private srv: SrvService,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PackingDialogCreateTtnComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.form = new FormGroup({
      sender: new FormControl('', [Validators.required]),
      senderWarehouse: new FormControl('', [Validators.required]),
      cashOnDelivery: new FormControl('', [Validators.required]),
      afterPaymentOnGoodsCost: new FormControl('', [Validators.required]),
      assessedValue: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      payment: new FormControl(1, [Validators.required]),
      dateSend: new FormControl(new Date(), [Validators.required]),
      recipient: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      recipientPhone: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      warehouseName: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
    });
    this.settlementSet.push({
      weight: '',
      height: '',
      width: '',
      length: '',
      cost: 0,
      specialCargo: false,
    });
  }

  ngOnInit(): void {
    this.onLoadTtnInfo();
    this.handleBarcodeProduct();
  }

  setForm(): void {
    this.form.get('sender').setValue(this.createTtnInfo.sender);
    this.form
      .get('senderWarehouse')
      .setValue(this.createTtnInfo.senderWarehouse);
    this.form.get('recipient').setValue(this.createTtnInfo.recipient);
    this.form.get('recipientPhone').setValue(this.createTtnInfo.recipientPhone);
    this.form.get('warehouseName').setValue(this.createTtnInfo.warehouseName);
    this.form.get('assessedValue').setValue(this.createTtnInfo.assessedValue);
    this.form.get('cashOnDelivery').setValue(this.createTtnInfo.cashOnDelivery);
    this.form
      .get('afterPaymentOnGoodsCost')
      .setValue(this.createTtnInfo.afterPaymentOnGoodsCost);

    this.updateProductList('');
  }

  onLoadTtnInfo(): void {
    this.ttnInfo$ = this.ps.onLoadTtnInfo(this.dialogData.orderList).pipe(
      tap((val) => {
        this.createTtnInfo = val;
        if (this.createTtnInfo.sizePositionList.length > 0) {
          this.settlementSet = [];
        }

        this.createTtnInfo.sizePositionList.forEach((value) => {
          this.costOrder += +value.cost;
          this.settlementSet.push({
            weight: +value.weight,
            height: +value.height,
            width: +value.width,
            length: +value.length,
            cost: +value.cost,
            specialCargo: +value.specialCargo,
          });
        });

        this.setForm();
      })
    );
  }

  addNewPlace(): void {
    this.settlementSet.push({
      weight: 0,
      height: 0,
      width: 0,
      length: 0,
      cost: 0,
      specialCargo: 0,
    });
  }

  deletePlace(index: number): void {
    this.settlementSet.splice(index, 1);
  }

  onCreateTtn(): any {
    if (this.checkAppPlacement()) {
      this.srv.errorMessage('Не все места назначены');
      return;
    }

    if (this.checkScanProduct()) {
      this.srv.errorMessage('Кол-во в заказе не совпадает с сканированием.');
      return;
    }

    if (+this.costOrder !== +this.form.get('assessedValue').value) {
      this.srv.errorMessage(
        'Оценочная стоимость упаковок не совпадает с общей'
      );

      return;
    }

    if (this.createTtnInfo.sendComment && !this.checkComment) {
      this.srv.errorMessage('Не прочитан комментарий к отгрузке');

      return false;
    }

    if (this.form.invalid) {
      return false;
    }

    const q = {
      sender: this.form.value.sender,
      senderWarehouse: this.form.value.senderWarehouse,
      dateSend: this.srv.convertDate(this.form.value.dateSend),
      afterPaymentOnGoodsCost: this.form.value.afterPaymentOnGoodsCost,
      assessedValue: this.form.get('assessedValue').value,
      cashOnDelivery: this.form.value.cashOnDelivery,
      payment: this.form.value.payment,
      pack: this.settlementSet,
      orderList: this.dialogData.orderList,
    };

    this.ps
      .onCreateTtn(q)
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при создании ТТН. Обратитесь в техподдержку'
          );
          return of('Error in method onInit deletePayment ' + err);
        }),
        untilDestroyed(this)
      )
      .subscribe((val) => {
        this.response = val;
        switch (val.code) {
          case 200:
            this.srv.successMessage('ТТН успешно создана');
            this.onPrintTtn(val.ttn);
            window.open(val.link);
            break;
          case 400:
            this.srv.errorMessage('Ошика при создании ТТН');
            break;
        }
      });
  }

  onSaveTtn(): any {
    if (this.checkAppPlacement()) {
      this.srv.errorMessage('Не все места назначены');
      return;
    }

    if (this.checkScanProduct()) {
      this.srv.errorMessage('Кол-во в заказе не совпадает с сканированием.');
      return;
    }

    if (+this.costOrder !== +this.form.get('assessedValue').value) {
      this.srv.errorMessage(
        'Оценочная стоимость упаковок не совпадает с общей'
      );

      return;
    }

    if (this.form.invalid) {
      return false;
    }

    const q = {
      sender: this.form.value.sender,
      senderWarehouse: this.form.value.senderWarehouse,
      dateSend: this.srv.convertDate(this.form.value.dateSend),
      afterPaymentOnGoodsCost: this.form.value.afterPaymentOnGoodsCost,
      assessedValue: +this.form.get('assessedValue').value,
      cashOnDelivery: this.form.value.cashOnDelivery,
      payment: this.form.value.payment,
      pack: this.settlementSet,
      orderList: this.dialogData.orderList,
    };

    this.ps
      .onSaveTtn(q)
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при создании ТТН. Обратитесь в техподдержку'
          );
          return of('Error in method onInit deletePayment ' + err);
        }),
        untilDestroyed(this)
      )
      .subscribe((val) => {
        switch (val.code) {
          case 200:
            this.srv.successMessage('Данные сохранены');
            break;
          case 400:
            this.srv.errorMessage('Ошика при сохранении');
            break;
        }
      });
  }

  onPrintTtn(ttn): void {
    this.ps
      .onPrintTtn(ttn)
      .pipe(
        catchError((err) => {
          return of('Error in method onInit deletePayment ' + err);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  onChangeDisabled(): void {
    if (this.disabled) {
      this.form.get('assessedValue').disable({ onlySelf: this.disabled });
      this.disabled = false;
    } else {
      this.form.get('assessedValue').enable({ onlySelf: this.disabled });
      this.disabled = true;
    }
  }

  saveSelfTtn(): void {
    const dialog = this.dialog.open(PackingDialogSaveSelfTtnComponent);
    dialog.afterClosed().subscribe((val) => {
      this.onSendOrderFromTtn(val.ttn, val.unitId);
    });
  }

  onSendOrderFromTtn(ttn, unitId): any {
    if (this.form.invalid) {
      return false;
    }

    const q = {
      sender: this.form.value.sender,
      senderWarehouse: this.form.value.senderWarehouse,
      dateSend: this.srv.convertDate(this.form.value.dateSend),
      afterPaymentOnGoodsCost: this.form.value.afterPaymentOnGoodsCost,
      assessedValue: this.form.get('assessedValue').value,
      cashOnDelivery: this.form.value.cashOnDelivery,
      payment: this.form.value.payment,
      pack: this.settlementSet,
      ttn,
      unitId,
      orderList: this.dialogData.orderList,
    };

    this.ps
      .onSendOrderFromTtn(q)
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при создании ТТН. Обратитесь в техподдержку'
          );
          return of('Error in method onInit deletePayment ' + err);
        }),
        untilDestroyed(this)
      )
      .subscribe((val) => {
        this.response = val;
        switch (val.code) {
          case 200:
            this.srv.successMessage('Заказ успешно упакован');
            this.dialogRef.close();
            break;
          case 400:
            this.srv.errorMessage('Ошика при упаковке заказа');
            break;
        }
      });
  }

  printCheck(): any {
    const newOrdNum: number[] = this.dialogData.orderList.map(
      (item: PackingOrder) => item
    );

    // Бросаю на нужный Url
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['print', 'check'], {
        queryParams: { newOrdNum },
      })
    );
    window.open(url, '_blank');
  }

  setParamData(item: any, index, num: number): void {
    switch (num) {
      case 1:
        this.settlementSet[index].weight = 0.5;
        this.settlementSet[index].height = 20;
        this.settlementSet[index].width = 20;
        this.settlementSet[index].length = 4;
        break;
      case 2:
        this.settlementSet[index].weight = 1;
        this.settlementSet[index].height = 25;
        this.settlementSet[index].width = 35;
        this.settlementSet[index].length = 4;
        break;
    }

    this.srv.successMessage('Размеры установлены');
  }

  changeCost(): void {
    this.costOrder = 0;
    this.settlementSet.forEach((item) => {
      item.cost = 0;
    });
    this.createTtnInfo.orderList.forEach((item) => {
      if (this.settlementSet[item.package - 1]) {
        this.costOrder += +item.countProd * +item.siteCost;
        this.settlementSet[item.package - 1].cost +=
          +item.countProd * +item.siteCost;
      }
    });
    this.updatePackProductInfo();
  }

  checkAppPlacement(): boolean {
    let checkError = false;
    this.createTtnInfo.orderList.map((item) => {
      item.error = !item.package;
      return item;
    });
    console.log('ewrw', this.createTtnInfo.orderList);

    this.createTtnInfo.orderList.forEach((item) => {
      if (item.error) {
        return (checkError = true);
      }
    });

    return checkError;
  }

  checkScanProduct(): boolean {
    let checkError = false;

    this.createTtnInfo.orderList.forEach((item) => {
      if (+item.countCheck !== +item.countProd) {
        return (checkError = true);
      }
    });

    return checkError;
  }

  resetScanProduct($event: ProductList): void {
    console.log('$event', $event);
    this.createTtnInfo.orderList?.map((item) => {
      if (item.id === $event.id) {
        item.countCheck = 0;
        item.countCheckClass = '';
      }

      return item;
    });
  }

  private handleBarcodeProduct(): void {
    this.srv.barcodeProductData$
      .pipe(
        filter(Boolean),
        tap(() => {
          this.srv.showLoadInfo$.next({
            status: true,
            titleSpinner: 'Ожидайте идет поиск товара',
          });
        }),
        untilDestroyed(this)
      )
      .subscribe((value: string) => {
        this.srv.showLoadInfo$.next({
          status: false,
        });

        this.updateProductList(value);
      });
  }

  checkProduct(product: ProductList): void {
    this.createTtnInfo.orderList?.map((item) => {
      if (+item.id === +product.id) {
        if (!item.countCheck) {
          item.countCheck = 0;
        }
        item.countCheck++;
      }

      if (+item.countProd === +item.countCheck) {
        item.countCheckClass = 'bg-success';
      } else if (+item.countProd >= +item.countCheck) {
        item.countCheckClass = 'bg-warning';
      } else if (+item.countProd <= +item.countCheck) {
        item.countCheckClass = 'bg-danger';
      }

      return item;
    });
    this.updateScanProductInfo();
  }

/*
  private updateProductList(productInfo: string): void {
    if (!this.createTtnInfo?.orderList) {
      return;
    }
    this.createTtnInfo.orderList?.map((item) => {
      if (item.barcodeList.includes(productInfo)) {
        if (!item.countCheck) {
          item.countCheck = 0;
        }
        item.countCheck++;
      }

      if (+item.countProd === +item.countCheck) {
        item.countCheckClass = 'bg-success';
      } else if (+item.countProd >= +item.countCheck) {
        item.countCheckClass = 'bg-warning';
      } else if (+item.countProd <= +item.countCheck) {
        item.countCheckClass = 'bg-danger';
      }

      return item;
    });
    this.updateScanProductInfo();
  }
*/

  private updateProductList(productInfo: string): void {

    if (!this.createTtnInfo?.orderList) {
      return;
    }

    let remainingCount = 1; // Счётчик сканирований, начнем с 1 (одно сканирование за раз)

    this.createTtnInfo.orderList?.forEach((item) => {
      // Сбрасываем класс строки для каждого товара
      item.countCheckClass = '';
      console.log('item.barcodeList.includes(productInfo)', item.barcodeList.includes(productInfo));

      if (
        item.barcodeList.includes(productInfo) &&
        remainingCount > 0 // Продолжаем обновлять, пока есть "остаток" от сканирования
      ) {
        if (!item.countCheck) {
          item.countCheck = 0;
        }

        const canUpdateCount = Math.min(
          remainingCount,
          +item.countProd - +item.countCheck
        ); // Сколько можем добавить
        if (canUpdateCount > 0) {
          item.countCheck += canUpdateCount; // Увеличиваем countCheck
          item.countCheckClass = 'bg-gray'; // Подсвечиваем строку
          remainingCount -= canUpdateCount; // Уменьшаем "остаток"
        }
      }

      // Обновляем класс строки в зависимости от count и countCheck
      if (+item.countProd === +item.countCheck) {
        item.countCheckClass = 'bg-success';
      } else if (+item.countProd > +item.countCheck) {
        item.countCheckClass = 'bg-warning';
      } else if (+item.countProd < +item.countCheck) {
        item.countCheckClass = 'bg-danger';
      }
    });
    this.updateScanProductInfo();
  }


  private updatePackProductInfo(): void {
    this.ps
      .onUpdatePackProductTtn(this.createTtnInfo.orderList)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  private updateScanProductInfo(): void {
    this.ps
      .updateScanProductInfo(this.createTtnInfo.orderList)
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
